<template>
  <TitleBar>Manage Campaign Cycles</TitleBar>
  <div class="py-2 px-1">
    <div
      class="grid mt-0 mx-3 surface-card shadow-2 p-3 border-1 border-50 border-round"
    >
      <table class="ml-auto">
        <tr>
          <td>
            <Button
              label="Create New Campaign Cycle"
              @click="
                selectedCampaignCycle = {};
                selectedCampaignCycle.campaign_id =
                  this.appStore.currentCampaign.id;
                toggleCreateModal();
              "
            />
          </td>
        </tr>
      </table>
      <DataTable
        class="col-12"
        :value="campaignCycles"
        responsiveLayout="scroll"
        :lazy="false"
        :loading="loading"
        :paginator="true"
        :rows-per-page-options="rowSelectionOptions"
        v-model:rows="rowsToShow"
        v-model:filters="filters"
        :totalRecords="totalRecords"
        filterDisplay="row"
      >
        <template #header>
          <div class="text-right flex flex-row-reverse"></div>
        </template>

        <Column
          field="campaign.name"
          header="Campaign Name"
          :sortable="true"
        ></Column>

        <Column field="id" header="Cycle Id" :sortable="true"></Column>

        <Column field="name" header="Cycle Name" :sortable="true">
          <template #body="{ data }">
            {{ data.name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              v-model="filterModel.value"
              type="text"
              @input="filterCallback()"
              class="p-column-filter"
              placeholder="Search by Cycle Name"
            />
          </template>
        </Column>

        <Column header="Edit Cycle">
          <template #body="slotProps">
            <Button
              label="Edit Cycle"
              @click="
                selectedCampaignCycle = slotProps.data;
                toggleEditModal();
              "
            />
          </template>
        </Column>
        <Column header="Assign Mailing(s)">
          <template #body="slotProps">
            <Button
              label="Search Mailings"
              @click="
                selectedCampaignCycle = slotProps.data;
                toggleMailingModal();
              "
            />
          </template>
        </Column>
      </DataTable>
      <table class="ml-auto">
        <tr>
          <td>
            <Button
              label="Create New Campaign Cycle"
              @click="
                selectedCampaignCycle = {};
                selectedCampaignCycle.campaign_id =
                  this.appStore.currentCampaign.id;
                toggleCreateModal();
              "
            />
          </td>
        </tr>
      </table>
    </div>
  </div>

  <CreateCampaignCycle
    :visible="toggleCreateModalVisible"
    :campaignCycle="selectedCampaignCycle"
    @updated="onUpdate"
  />

  <EditCampaignCycle
    :visible="toggleEditModalVisible"
    :campaignCycle="selectedCampaignCycle"
    @updated="onUpdate"
  />

  <AssignMailing
    v-model:visible="toggleMailingModalVisible"
    :campaignCycle="selectedCampaignCycle"
    :allMailings="mailings"
    :assignedMailings="allMailingsForCycle"
    @updated="onUpdate"
  />
</template>

<script>
import CampaignCycle from "@/models/CampaignCycles";
import Mailing from "@/models/Mailing";

import EditCampaignCycle from "@/components/modals/EditCampaignCycle";
import CreateCampaignCycle from "@/components/modals/CreateCampaignCycle";
import AssignMailing from "@/components/modals/AssignMailing";
import EventBus from "@/EventBus.js";
import TitleBar from "@/components/TitleBar.vue";
import { useAppStore } from "@/stores/app";
import { FilterMatchMode } from "primevue/api";

export default {
  name: "ManageCampaignCycles",
  components: {
    EditCampaignCycle,
    CreateCampaignCycle,
    TitleBar,
    AssignMailing,
  },
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return {
      mailings: [],
      loading: false,
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },

      selectedCampaignCycle: null,
      allMailingsForCycle: null,

      toggleCreateModalVisible: false,
      toggleEditModalVisible: false,
      toggleMailingModalVisible: false,

      totalRecords: 0,
      rowSelectionOptions: [10, 20, 50],
      rowsToShow: 10,
    };
  },
  mounted() {
    this.loadCampaignCycles();
    this.loadMailings();
  },
  methods: {
    loadCampaignCycles() {
      this.loading = true;

      CampaignCycle.where("campaign_id", this.appStore.currentCampaign.id)
        .select({ campaign: ["id", "name"] })
        .include(["campaign", "mailings"])
        .get()
        .then((response) => {
          this.campaignCycles = response.data;
          this.totalRecords = response.meta.total;
        })
        .catch()
        .then(() => (this.loading = false));
    },
    loadMailings() {
      this.loading = true;

      Mailing.where("campaign_id", this.appStore.currentCampaign.id)
        .where("include_future_mailings", 1)
        .get()
        .then((response) => {
          this.mailings = response.data;
          this.totalRecords = response.meta.total;
        })
        .catch()
        .then(() => (this.loading = false));
    },
    toggleCreateModal() {
      EventBus.emit("toggle-create-modal");
    },
    toggleEditModal() {
      EventBus.emit("toggle-edit-modal");
    },
    toggleMailingModal() {
      this.allMailingsForCycle = this.selectedCampaignCycle.mailings || [];
      EventBus.emit("toggle-mailing-modal");
    },
    onUpdate(value) {
      console.log(value);
      this.loadCampaignCycles();
      this.loadMailings();
    },
  },
};
</script>
