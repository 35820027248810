<template>
  <Dialog
    v-model:visible="showModal"
    :modal="true"
    header="Create New Campaign Cycle"
    :dismissable-mask="true"
    :close-on-escape="true"
  >
    <div :style="{ width: '50vw' }">
      <div class="card card-w-title">
        <div class="p-grid form-group">
          <div>
            <div v-if="form.id != null" class="p-field mb-4"></div>

            <div class="p-field mb-3">
              <label
                for="campaignNameInput"
                class="block text-900 font-medium mb-2"
                >Campaign Id</label
              >
              <InputText
                type="text"
                id="campaignNameInput"
                class="p-inputtext p-component w-full p-3"
                :class="
                  validationErrors && validationErrors.campaign_id
                    ? 'p-invalid'
                    : ''
                "
                :disabled="true"
                v-model="form.campaign_id"
              />
              <small
                class="p-error"
                v-if="validationErrors && validationErrors.campaign_id"
              >
                {{ validationErrors.campaign_id[0] }}
              </small>
            </div>

            <div class="p-field mb-3">
              <label for="labelInput" class="block text-900 font-medium mb-2"
                >Name</label
              >
              <InputText
                type="text"
                id="labelInput"
                class="p-inputtext p-component w-full p-3"
                :class="
                  validationErrors && validationErrors.name ? 'p-invalid' : ''
                "
                v-model="form.name"
                v-on:keyup.enter="console.log()"
              />
              <small
                class="p-error"
                v-if="validationErrors && validationErrors.name"
              >
                {{ validationErrors.name[0] }}
              </small>
            </div>
            <div class="text-center">
              <Button
                label="CREATE"
                class="p-button p-component p-3"
                :loading="loading"
                @click="saveChanges"
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import EventBus from "@/EventBus";
import CampaignCycle from "@/models/CampaignCycles";
import { useAppStore } from "@/stores/app";

export default {
  name: "CreateCampaignCycle",
  props: {
    campaignCycle: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["updated"],
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return {
      form: {
        name: "",
        id: "",
        campaign_id: "",
      },
      validationErrors: [],
      loading: false,

      showModal: false,
    };
  },
  watch: {
    campaignCycle() {
      this.form.campaign_id = this.appStore.currentCampaign.id;
      this.form.name = this.campaignCycle.name;
      this.form.id = this.campaignCycle.id;
    },
  },

  mounted() {
    EventBus.on("toggle-create-modal", () => {
      this.showModal = !this.showModal;
    });
  },
  methods: {
    saveChanges() {
      this.loading = true;

      let campaignCycle = new CampaignCycle({});

      campaignCycle.campaign_id = this.form.campaign_id;
      campaignCycle.name = this.form.name;
      campaignCycle.id = this.form.id;

      campaignCycle
        .save()
        .then(() => {
          this.$emit("updated", {
            data: this.form,
            campaignCycle: this.campaignCycle,
          });
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "New Campaign Cycle Created",
            life: 3000,
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Error Creating Campaign Cycle",
            life: 3000,
          });
        })
        .then(() => {
          this.loading = false;
          this.showModal = false;
        });
    },
  },
};
</script>
