<template>
  <Dialog
    v-model:visible="showModal"
    :modal="true"
    header="Assign Mailing(s)"
    :dismissable-mask="true"
    :close-on-escape="true"
  >
    <div :style="{ width: '50vw' }">
      <div class="card card-w-title">
        <div class="p-grid form-group">
          <div>
            <div class="p-field mb-3">
              <DataTable
                dataKey="id"
                tableStyle="min-width: 50rem"
                :value="allMailings"
                v-model:selection="selectedMailings"
                v-model:filters="filters"
                filter-display="row"
              >
                <Column
                  selectionMode="multiple"
                  headerStyle="width: 3rem"
                ></Column>
                <Column field="name" header="Name"> </Column>

                <Column field="description" header="Description">
                  <template #body="{ data }">
                    {{ data.description }}
                  </template>
                  <template #filter="{ filterModel, filterCallback }">
                    <InputText
                      v-model="filterModel.value"
                      type="text"
                      @input="filterCallback()"
                      class="p-column-filter"
                      placeholder="Search by Description"
                    /> </template
                ></Column>
                <Column field="mailing_date" header="Mailing Date"></Column>
              </DataTable>
            </div>
            <div class="text-center">
              <Button
                label="Assign to Campaign Cycle"
                class="p-button p-component p-3"
                :loading="loading"
                @click="saveChanges"
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import EventBus from "@/EventBus";
import CampaignCycle from "@/models/CampaignCycles";
import { FilterMatchMode } from "primevue/api";

export default {
  name: "AssignMailing",
  props: {
    allMailings: {
      type: Array,
      required: true,
    },
    campaignCycle: {
      type: Object,
      default: () => ({}),
    },
    assignedMailings: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:visible", "updated"],
  data() {
    return {
      validationErrors: [],
      loading: false,
      selectedMailings: this.assignedMailings,
      showModal: false,
      filters: {
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  mounted() {
    EventBus.on("toggle-mailing-modal", () => {
      this.showModal = !this.showModal;
    });
  },
  watch: {
    assignedMailings: {
      immediate: true,
      handler(newVal) {
        this.selectedMailings = newVal ? [...newVal] : [];
      },
    },
  },
  methods: {
    saveChanges() {
      this.loading = true;

      const requestData = {
        campaign_cycle_id: this.campaignCycle.id,
        campaign_id: this.campaignCycle.campaign_id,
        mailing_ids: this.selectedMailings.map((mailing) => mailing.id),
      };

      const campaignCycle = new CampaignCycle();
      campaignCycle
        .syncMailings(requestData)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Mailings Assigned Successfully",
            life: 3000,
          });
        })
        .catch((error) => {
          console.error(
            "An error occurred while updating the mailing:",
            error.response
          );
        })
        .finally(() => {
          this.loading = false;
          this.showModal = false;
          this.$emit("updated");
        });
    },
  },
};
</script>
