import Model from "./Model";

export default class CampaignCycles extends Model {
  resource() {
    return "campaign-cycles";
  }
  syncMailings(data) {
    return this.$http.post(
      `${this.baseURL()}/campaign-cycles/sync-mailings`,
      data
    );
  }
}
